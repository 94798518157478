import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const Spinner = ({ mLoading, isLoading, isFetching, size = `sm` }) => {
  return mLoading || isLoading || isFetching ? (
    <div className="relative">
      <div className="absolute top-80 bottom-80 w-full h-full flex justify-center items-center  z-40  p-4 text-2xl text-red-600">
        <FontAwesomeIcon size={size} icon={faSpinner} spin={true} />
      </div>
    </div>
  ) : (
    <></>
  );
};
