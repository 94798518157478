import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { setCurrentService } from "App/Redux/actions";
import { Reload } from "App/Components/Common/Reload";
import { useHistory, useRouteMatch } from "react-router";
import { CardField } from "App/Components/Common/CardField";
import { Collapsable } from "App/Components/Common/Collapsable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faSpinner } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  faCopy,
  faPaste,
} from "@fortawesome/free-solid-svg-icons";
import { Container, EditStyledButton, PageHeader } from "App/Styled";

export const ServicePreview = () => {
  const {
    url,
    params: { serviceId },
  } = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isCopied, setIsCopied] = useState(false)
  const [isCopiedTootip, setIsCopiedTooltip] = useState(false)

  useEffect(() => {
    setIsCopiedTooltip(true)
    setTimeout((
      setIsCopiedTooltip(false)
    ), 500)
  }, [isCopied])

  const {
    data: servicePreview,
    isLoading,
    error,
    refetch,
    isFetching,
  } = useQuery(
    `servicePreviewApi`,
    async () =>
      await fetch(`${ process.env.REACT_APP_BACKEND_API }/services/${ serviceId }`)
        .then((res) => res.json())
        .then((res) => {
          return res.data.service;
        }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        dispatch(setCurrentService(res));
      },
    }
  );

  return (
    <>
      <Container>
        <PageHeader>
          <div>Service</div>
          <EditStyledButton onClick={() => history.push(`${ url }/update`)}>
            <span>Edit</span>
            <FontAwesomeIcon
              icon={faPen}
              className="text-white mt-0.5"
              size="sm"
            />
          </EditStyledButton>
        </PageHeader>
        {isLoading || isFetching ? (
          <div className="text-center p-6">
            <FontAwesomeIcon
              icon={faSpinner}
              spin={true}
              size="1x"
              className="text-red-600"
            />
          </div>
        ) : error ? (
          <Reload refetch={() => refetch()} />
        ) : (
          <>
            <div className="flex flex-row m-3">
              <div className="flex-1">
                <div className="rounded-md overflow-hidden py-4">
                  <div className="flex items-start justify-evenly">
                    <div className="flex-1 mx-2 shadow-lg rounded">
                      <Collapsable>

                        <div className={`mr-2`}>
                          <CardField
                            title="Name"
                            value={servicePreview?.name}
                          />
                        </div>
                        <div className={`mr-2`}>
                          <CardField
                            title="Status"
                            value={servicePreview?.status}
                            badge={true}
                          />
                        </div>

                        <CopyToClipboard text={servicePreview?._serviceId} onCopy={() => setIsCopied(true)}>
                          <span data-tip="Copy" className="tooltip float-right"> <FontAwesomeIcon className="text-gray-400 float-right transform transition hover:text-blue-600 hover:scale-105 cursor-pointer" icon={faCopy} /></span>
                        </CopyToClipboard>
                        <CardField
                          title="Service Id"
                          value={servicePreview?._serviceId}
                          badge={false}
                        />

                        <CopyToClipboard text={servicePreview?.serviceSecret}>
                          <span data-tip="Copy" className="tooltip float-right"> <FontAwesomeIcon className="text-gray-400 float-right transform transition hover:text-blue-600 hover:scale-105 cursor-pointer" icon={faCopy} /></span>
                        </CopyToClipboard>
                        <CardField
                          title="Service Secret"
                          value={servicePreview?.serviceSecret}
                          badge={false}
                        />

                        <div className={`mr-2`}>
                          <CardField
                            title="Description"
                            value={servicePreview?.description}
                          />
                        </div>
                        <div className={`mr-2`}>
                          <CardField
                            title="Updated At"
                            value={moment(servicePreview.updatedAt).format(
                              `YYYY-MM-DD`
                            )}
                          />
                        </div>
                        <div className={`mr-2`}>
                          <CardField
                            title="Created At"
                            value={moment(servicePreview.createdAt).format(
                              `YYYY-MM-DD`
                            )}
                          />
                        </div>

                        <div className="grid grid-cols-1">
                          {servicePreview?.redirectUris?.map(
                            (redirectUri, idx) => (
                              <div
                                key={idx}
                                className="flex items-start justify-evenly"
                              >
                                <div className="flex-1 mx-2 shadow-lg rounded">
                                  <Collapsable title="Redirect uri">
                                    <a href={redirectUri.uri}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <CardField
                                        title="Uri"
                                        value={redirectUri.uri}
                                      >
                                      </CardField>
                                    </a>
                                    <CardField
                                      title="Purpose"
                                      value={redirectUri.purpose}
                                    />
                                  </Collapsable>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                        <div className="grid grid-cols-1">
                          {servicePreview?.postLogoutRedirectUris?.map(
                            (redirectUri, idx) => (
                              <div
                                key={idx}
                                className="flex items-start justify-evenly"
                              >
                                <div className="flex-1 mx-2 shadow-lg rounded">
                                  <Collapsable title="Logout Redirect URIs">
                                    <a href={redirectUri.uri}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <CardField
                                        title="Uri"
                                        value={redirectUri.uri}
                                      >
                                      </CardField>
                                    </a>
                                    <CardField
                                      title="Purpose"
                                      value={redirectUri.purpose}
                                    />
                                  </Collapsable>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </Collapsable>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Container>
    </>
  );
};
