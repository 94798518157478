/* eslint-disable no-unused-vars */
import React from "react";
import { VeronicasLogo } from "App/Assets";
import { useAuth } from "oidc-react";
import { StyledButton } from "App/Styled";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { APP_BASEURL, IDP_FRONTEND, UNAUTHENTICATED_ROUTES } from "App/Config";

export const Topbar = ({ children }) => {
  const location = useLocation();
  const user = useSelector(({ user: { user } }) => user);
  const validRoute = !UNAUTHENTICATED_ROUTES.includes(location.pathname);
  // const oidcAuth = useAuth();
  const { id_token } = useSelector(({ user: { user } }) => user);

  if (user?.access_token && validRoute) {
    return (
      <div className="bg-gray-100 h-sreen">
        <div className="flex bg-white">
          <div className="flex-1">
            <a href="/services">
              <img
                src={VeronicasLogo}
                alt="logo"
                style={{ height: "80px", paddingLeft: "20px" }}
              />
            </a>
          </div>
          <div className="flex-1 px-4 pt-5">
            <StyledButton
              onClick={() => {
                localStorage.setItem(`isLogOut`, true);
                window.location.assign(`${IDP_FRONTEND}?logout=${APP_BASEURL}`);
              }}
              //  onClick={() =>
              //   oidcAuth.signOutRedirect({
              //     id_token_hint: id_token,
              //   })
              // }
            >
              Sign Out
            </StyledButton>
          </div>
        </div>
        {children}
      </div>
    );
  } else {
    return children;
  }
};
