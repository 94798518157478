import createWebStorage from "redux-persist/lib/storage/createWebStorage";

const createNoopStorage = () => {
  return {
    getItem: () => {
      return Promise.resolve(``);
    },
    setItem: (key, item) => {
      return Promise.resolve(item);
    },
    removeItem: () => {
      return Promise.resolve(``);
    },
  };
};
const storage =
  typeof window !== `undefined`
    ? createWebStorage(`local`)
    : createNoopStorage();
export default storage;
