import React, { useEffect, useState } from "react";
import moment from "moment";
import { useQuery, useMutation } from "react-query";
import { Spinner } from "App/Components/Common/Spinner";
import { useHistory, useRouteMatch } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, PageHeader, StyledButton } from "App/Styled";
import { ReactNotification } from "App/Components/Common/ReactNotification";
import { SearchInputField } from "App/Components/Common/SearchInputField";
import { useDebouncedCallback } from "use-debounce/lib";
import {
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  Avatar,
  Badge,
  Pagination,
} from "@windmill/react-ui";
import {
  faPlusCircle,
  faSortUp,
  faSortDown,
  faTrash,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { Reload } from "App/Components/Common/Reload";
import { offset } from "App/Config";
import axios from "axios";

const tableColumns = [
  { label: `Name`, value: `name` },
  { label: `Status`, value: `status` },
  { label: `Updated`, value: `updatedAt` },
  { label: `Created`, value: `createdAt` },
];

export const AllService = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sort, setSort] = useState({
    sorting: false,
    activeSort: ``,
  });
  const [isMasterFrenchise, setIsMasterFrenchise] = useState(false);
  const [searchQuery, setSearchQuery] = useState(``);
  const history = useHistory();
  const { url } = useRouteMatch();

  // fetching all frenchises
  const {
    isLoading,
    data: serviceData,
    error,
    refetch: getAllServices,
    isFetching,
  } = useQuery(
    `serviceDataApi`,
    async () =>
      await fetch(
        `${ process.env.REACT_APP_BACKEND_API }/services?search={"query": "${ searchQuery.length ? searchQuery : ``
        }", "onlyMasterFranchises": ${ isMasterFrenchise }}&sort="${ sort.sorting ? sort.activeSort : ``
        }"&page=${ currentPage }&offset=${ offset }`
      )
        .then((res) => res.json())
        .then((res) => {
          return res.data;
        }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
    }
  );

  // delete franchise
  const deleteMutation = useMutation(
    async (id) =>
      await axios({
        method: `DELETE`,
        url: `${ process.env.REACT_APP_BACKEND_API }/services/${ id }`,
      }).then((res) => {
        res.data && getAllServices();
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  useEffect(() => {
    getAllServices();
  }, [isMasterFrenchise, sort.sorting, currentPage]);

  const handleSorting = (action, active) => {
    if (action) {
      setSort((prevState) => ({
        ...prevState,
        sorting: action,
        activeSort: active,
      }));
    } else {
      setSort((prevState) => ({
        ...prevState,
        sorting: action,
        activeSort: ``,
      }));
    }
  };

  const callFranchiseQuery = useDebouncedCallback(() => {
    getAllServices();
  }, 300);

  return (
    <Container>
      {deleteMutation.isError && (
        <ReactNotification
          action="error"
          message={deleteMutation?.error?.response?.data?.error?.error}
        />
      )}
      <PageHeader>
        <div>Services</div>
        <div className="flex items-center">
          <div className="text-end">
            <SearchInputField
              type="text"
              placeholder="Type here..."
              value={searchQuery}
              setValue={(e) => {
                setSearchQuery(e.target.value);
                callFranchiseQuery();
              }}
            />
          </div>
        </div>
      </PageHeader>

      <div className="flex justify-end items-center p-2">
        <StyledButton onClick={() => history.push(`${ url }/addService`)}>
          <div className="flex gap-2 text-white">
            <span>Add </span>
            <FontAwesomeIcon icon={faPlusCircle} size="lg" />
          </div>
        </StyledButton>
      </div>

      <Spinner
        mLoading={deleteMutation.isLoading}
        isLoading={isLoading}
        isFetching={isFetching}
      />

      <div
        className={`bg-white mb-1 sm:mb-0 justify-center items-center px-2 ${ (deleteMutation.isLoading || isLoading || isFetching) &&
          `opacity-30 pointer-events-none`
          } `}
      >
        {error ? (
          <Reload refetch={() => getAllServices()} />
        ) : (
          <>
            <div className="overflow-x-auto rounded-t-md">
              <table className="w-full border-collapse">
                <TableHeader>
                  <TableRow>
                    {tableColumns.map((item, idx) => {
                      return (
                        <TableCell key={idx}>
                          <div
                            className={`${ sort.activeSort === item.value && `text-red-500 `
                              } cursor-pointer select-none text-sm flex`}
                          >
                            <div
                              onClick={() => {
                                handleSorting(!sort.sorting, item.value);
                              }}
                              className="flex"
                            >
                              {item.label}
                            </div>
                            <div
                              className="ml-1 px-1 rounded-sm cursor-pointer flex relative"
                              onClick={() =>
                                handleSorting(!sort.sorting, `-` + item.value)
                              }
                            >
                              <FontAwesomeIcon
                                icon={
                                  sort.activeSort.includes(`-`) &&
                                    sort.activeSort == `-` + item.value
                                    ? faSortUp
                                    : faSortDown
                                }
                                size="lg"
                                className={`${ sort.activeSort.includes(`-`) &&
                                  sort.activeSort == `-` + item.value
                                  ? `text-red-600`
                                  : `-mt-1.5`
                                  }`}
                              />
                            </div>
                          </div>
                        </TableCell>
                      );
                    })}
                    <TableCell className="float-right">Actions</TableCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {serviceData?.services?.length != 0 ? (
                    serviceData?.services?.map((service, index) => (
                      <TableRow
                        key={index.toString()}
                        className="hover:bg-gray-100"
                      >
                        <td className="px-4 py-0.5 z-0">
                          <span className="transform transition hover:text-blue-600  hover:-translate-y-0 hover:scale-105  cursor-pointer font-semibold" onClick={() => {
                            history.push(`${ url }/service/${ service._id }`);
                          }}>{service.name}</span>
                        </td>
                        {/* <td className="px-4 py-0.5 z-0">
                          <span className="font-semibold ">
                            {service.env ? service.env:  <Badge type="danger">N/A</Badge>}
                          </span>
                        </td> */}
                        <td className="px-4 py-0.5 z-0">
                          <Badge type="success">{service.status}</Badge>
                        </td>
                        <td className="px-4 py-0.5 z-0">
                          <span>
                            {moment(service.createdAt).format(`YYYY-MM-DD`)}
                          </span>
                        </td>
                        <td className="px-4 py-0.5 z-0">
                          <span>
                            {moment(service.updatedAt).format(`YYYY-MM-DD`)}
                          </span>
                        </td>
                        {/* Actions TD */}
                        <td className="px-6 py-0.5 z-0 flex float-right text-gray-400">
                          <span data-tip="View" className="tooltip">
                            <FontAwesomeIcon
                              className=" mx-1 transform transition  hover:text-blue-600  hover:-translate-y-0 hover:scale-105  cursor-pointer "
                              icon={faEye}
                              onClick={() => {
                                history.push(`${ url }/service/${ service._id }`);
                              }}
                            />
                          </span>
                          {/* <span data-tip="Delete" className="tooltip">
                            <FontAwesomeIcon
                              className="mx-1 transform transition  hover:text-red-600  hover:-translate-y-0 hover:scale-105 cursor-pointer"
                              icon={faTrash}
                              size="1x"
                              onClick={() => deleteMutation.mutate(service._id)}
                            />
                          </span> */}
                        </td>
                      </TableRow>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="12" className="text-center p-6">
                        <div>No Service Found</div>
                      </td>
                    </tr>
                  )}
                </TableBody>
              </table>
            </div>

            <div className="px-4 py-2">
              <Pagination
                totalResults={serviceData?.totalRecords}
                resultsPerPage={offset}
                onChange={(page) => setCurrentPage(page)}
                label="Table navigation"
              />
            </div>
          </>
        )}
      </div>
    </Container>
  );
};
