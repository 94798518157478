import tw from "tailwind-styled-components";

export const LoginButton = tw.button`
    py-2
    px-4 
    w-full 
    flex 
    justify-center 
    items-center  
    bg-red-600 
    hover:bg-red-700 
    focus:ring-red-500 
    focus:ring-offset-red-200 
    text-white transition 
    ease-in duration-200 
    text-center text-base 
    font-semibold 
    shadow-md 
    focus:outline-none 
    focus:ring-2 
    focus:ring-offset-2 
    rounded-lg 
`;
export const LoginContainer = tw.div`
    flex 
    flex-col 
    justify-center 
    text-center 
    w-full 
    px-16 
    pt-44
    my-auto 
    md:justify-start 
    md:pt-0 
    md:px-24 
    lg:px-52
`;
