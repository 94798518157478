import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentService: ``,
};

const serviceSlice = createSlice({
  name: `service`,

  initialState,
  reducers: {
    setCurrentService: (state, { payload }) => {
      state.currentService = payload;
    },
  },
});

export const { setCurrentService } = serviceSlice.actions;

export const service = serviceSlice.reducer;
