import React from "react";
import { LoginButton, LoginContainer } from "./style";
// import { useAuth } from "oidc-react";
import {
  imageFive,
  imageFour,
  imageOne,
  imageSeven,
  imageSix,
  imageThree,
  imageTwo,
  VeronicasLogo,
} from "App/Assets";
import { IDP_FRONTEND } from "App/Config";
import { getNonce } from "App/Config/getNonce";

export const Login = () => {
  // const oidcAuth = useAuth();
  const imageGenerator = () => {
    const images = [
      imageOne,
      imageTwo,
      imageThree,
      imageFour,
      imageFive,
      imageSix,
      imageSeven,
    ];
    return images[Math.floor(Math.random() * Math.floor(7))];
  };

  const handleSignIn = () => {
    const nonceToken = getNonce(16, `aA0`);
    localStorage.setItem(`nonce`, nonceToken);
    window.location.assign(
      `${IDP_FRONTEND}?serviceId=${process.env.REACT_APP_OIDC_CLIENT_ID}&redirectUri=${process.env.REACT_APP_OIDC_REDIRECT_URI}&nonce=${nonceToken}`
    );
  };
  return (
    <div className="flex flex-wrap w-full">
      <div className="flex flex-col w-full md:w-1/2 sm-2:w-1/2">
        <LoginContainer>
          <img
            src={VeronicasLogo}
            alt="Logo will go here"
            className="text-left w-60 h-36"
          />
          <LoginButton onClick={() => handleSignIn()}>Sign In</LoginButton>
          <p className="text-gray-400 text-right text-sm mt-2">
            Let's get you connected
          </p>
        </LoginContainer>
      </div>
      <div className="w-1/2 shadow-2xl md:w-1/2 sm-2:w-1/2">
        <img
          className="object-cover w-full h-screen "
          src={imageGenerator()}
          alt="img"
        />
      </div>
    </div>
  );
};
