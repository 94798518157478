import React, { useEffect } from "react";
import { useAuth } from "oidc-react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { unsetUser } from "App/Redux/actions";

export const OidcLogoutRedirectPage = () => {
  const oidcAuth = useAuth();
  const history = useHistory();
  const dispatch = useDispatch()


  useEffect(() => {
    if(!oidcAuth?.userData){
        dispatch(unsetUser());
        history.push(`/login`)
    }else{
        history.push(`/services`)
    }

  }, [process.browser,oidcAuth,history])
  return <p>loggin your out, see you soon</p>;
};
