import React from "react";

export const CardField = ({ title, value, multiple, badge, coord, link }) => {
  return (
    <div className="grid grid-cols-6 my-1 justify-items-start border-b-1 text-sm">
      <div className="col-span-2 text-red-700">{title} </div>
      {/* <div className="col-span-1">-</div> */}
      <div className=" col-span-2 flex flex-wrap  font-medium my-auto">
        {multiple ? (
          value.length ? (
            value.map((val, index) => {
              return (
                <span
                  key={index}
                  className={`m-0.5 py-1 px-1.5 break-words max-w-px-120 text-sm rounded-xl text-white bg-red-600`}
                >
                  {val}
                </span>
              );
            })
          ) : (
            `N/A`
          )
        ) : value ? (
          badge ? (
            <span
              className={`ml-0.1 py-0.5 px-1.5 break-words max-w-px-120 text-sm rounded-xl text-white bg-red-600`}
            >
              {value}
            </span>
          ) : coord ? (
            <div className="flex justify-evenly">
              [<p className="mr-0.5">{value.lat}</p>,
              <p className="ml-0.5">{value.long}</p> ]
            </div>
          ) : link ? (
            <a
              className="border-b-2 text-red-600"
              href={value}
              target="_blank"
              rel="noreferrer"
            >
              {title}
            </a>
          ) : (
            value
          )
        ) : (
          `Not Provided`
        )}
      </div>
    </div>
  );
};
