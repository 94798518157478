import {
  faChevronCircleDown,
  faPencilAlt,
  faSpinner,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Transition } from "@windmill/react-ui";
import React, { useState, useEffect } from "react";

export const Collapsable = ({
  title,
  editAble,
  deleteAble,
  isLoading,
  isActive,
  children,
}) => {
  const [active, setActive] = useState(true);

  useEffect(() => {
    if (isActive) {
      setActive(false);
    }
  }, [isActive]);

  return (
    <>
      <div
        className={`flex flex-row justify-between bg-red-600 items-center px-3 py-0.5 ${
          active ? `rounded-t-md` : `rounded-md`
        }  `}
      >
        <div className="text-md font-medium text-white rounded-t-md capitalize">
          {title}
        </div>
        <div className="flex gap-3 cursor-pointer">
          {editAble && (
            <div>
              <FontAwesomeIcon
                onClick={editAble}
                icon={faPencilAlt}
                className={`text-white`}
              />
            </div>
          )}
          {deleteAble && (
            <div>
              <FontAwesomeIcon
                onClick={deleteAble}
                icon={isLoading ? faSpinner : faTrashAlt}
                spin={isLoading}
                className={`text-white `}
              />
            </div>
          )}
          <div>
            <FontAwesomeIcon
              onClick={() => setActive(!active)}
              icon={faChevronCircleDown}
              className={` text-white transform transition duration-100 ${
                active && `rotate-180 `
              }`}
            />
          </div>
        </div>
      </div>
      <Transition
        show={active}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-100 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="left-0 rounded-md shadow-md">
          <div className="py-2 px-4 bg-white rounded-md">{children}</div>
        </div>
      </Transition>
    </>
  );
};
