/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
// import { useAuth } from 'oidc-react'
import { useHistory } from "react-router-dom";

import { useMutation } from "react-query";
import { OIDC_AUTHORITY, OIDC_BACKEND_API } from "App/Config";
import axios from "axios";
import { setUser } from "App/Redux/actions";
import { useDispatch } from "react-redux";

export const OidcRedirectPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isToken, setIsToken] = useState(true);
  const url = window.location.search;
  const urlParams = new URLSearchParams(url);
  const authCode = urlParams.get(`authCode`);
  const nonceId = localStorage.getItem(`nonce`);

  const { mutate: getTokens } = useMutation(
    async () =>
      await axios
        .get(`${OIDC_AUTHORITY}/api/user/get-tokkens/${authCode}/${nonceId}`)
        .then((res) => {
          return res.data.data;
        }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 1,
      onSuccess: (res) => {
        if (res?.accessToken) {
          setIsToken(false);
          localStorage.setItem(`accessToken`, res?.accessToken);
          localStorage.setItem(`idToken`, res?.idToken);
          localStorage.setItem(`refreshToken`, res?.refreshToken);
          localStorage.setItem(`accessTokenExpiry`, res?.accessTokenExpiry);
          axios
            .get(`${OIDC_AUTHORITY}/api/user/me`, {
              headers: {
                authorization: res?.accessToken,
              },
            })
            .then((res2) => res2?.data?.data?.data)
            .then((res2) => {
              const user = {};
              user.access_token = res?.accessToken;
              user.profile = res2;
              dispatch(setUser(user));
              res2 && res?.accessToken && history.push(`/dashboard`);
            });
        }
      },
    }
  );

  useEffect(() => {
    if (
      window.location.search.includes(`error`) &&
      window.location.search.includes(`error_description`)
    ) {
      history.push(`/login`);
    } else if (
      window.location.search.includes(`nonceId`) &&
      window.location.search.includes(`authCode`)
    ) {
      isToken && getTokens();
    } else {
      history.push(`/404`);
    }
  }, []);

  return <p>Please wait, redirecting you to services...</p>;
};
