import { combineReducers } from "redux";

import { user } from "./slices/user";
import { service } from "./slices/Service";

const rootReducer = combineReducers({
  user,
  service,
});

export { rootReducer };
