import React from "react";

export const Reload = ({ refetch }) => {
  return (
    <div className=" text-center py-10">
      Something Went Wrong...
      <span
        onClick={() => refetch()}
        className="border-b-1 border-red-600 mx-1 text-red-600  text-base cursor-pointer"
      >
        Reload
      </span>
    </div>
  );
};
