import React from "react";
import ReactDOM from "react-dom";
import "./index.css";

import { Provider as ReduxProvider } from "react-redux";
import { store, persistor } from "./App/Redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "react-query";
import { Router } from "./App/Router";
// import { ReactQueryDevtools } from "react-query/devtools";
import { AuthProvider } from "App/AuthProvider";
import { Topbar } from "App/Components/Topbar";

const queryClient = new QueryClient();

ReactDOM.render(
	<QueryClientProvider client={queryClient}>
		<ReduxProvider store={store}>
			<AuthProvider>
				<PersistGate loading={null} persistor={persistor}>
					<BrowserRouter>
						<React.StrictMode>
							<Topbar>
								<Router />
							</Topbar>
						</React.StrictMode>
					</BrowserRouter>
				</PersistGate>
			</AuthProvider>
		</ReduxProvider>
		{/* <ReactQueryDevtools initialIsOpen={false} /> */}
	</QueryClientProvider>,
	document.getElementById(`root`)
);
reportWebVitals();
