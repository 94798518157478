/* eslint-disable camelcase */
import { oidcConfig } from "./oidc";
import Oidc from "oidc-client";
import { UserManager as _UserManager } from "oidc-react";
import { OIDC_AUTHORITY } from "./auth";

const oidcMetaData = {
  issuer: OIDC_AUTHORITY,
  authorization_endpoint: OIDC_AUTHORITY + `/authorize`,
  token_endpoint: OIDC_AUTHORITY + `/token`,
  userinfo_endpoint: OIDC_AUTHORITY + `/me`,
  end_session_endpoint: OIDC_AUTHORITY + `/session/end`,
  check_session_iframe: OIDC_AUTHORITY + `/checksession`,
  revocation_endpoint: OIDC_AUTHORITY + `/revocation`,
  introspection_endpoint: OIDC_AUTHORITY + `/introspect`,
};

export const UserManager = new _UserManager({
  ...oidcConfig,
  metadata: {
    ...oidcMetaData,
  },
  client_authentication: `client_secret_basic`,
  userStore: new Oidc.WebStorageStateStore({
    store: window.localStorage,
  }),
});
