/* eslint-disable camelcase */
  import { OIDC_AUTHORITY, OIDC_CLIENT_ID, OIDC_REDIRECT_URI,OIDC_CLIENT_SECRET } from "./auth";

export const oidcConfig = {
  client_id: OIDC_CLIENT_ID,
  authority: OIDC_AUTHORITY,
  redirect_uri: OIDC_REDIRECT_URI,
  client_secret: OIDC_CLIENT_SECRET,
  response_type: `code`,
  silentRequestTimeout: 60,
  scope: `openid offline_access profile`,
  automaticSilentRenew: true,
};
