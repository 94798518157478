import { unsetUser } from "App/Redux/actions";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const logOut = localStorage.getItem(`isLogOut`);
    if (logOut) {
      dispatch(unsetUser());
      localStorage.clear();
    }
  }, []);

  if (!process.browser) return children;
  return children;
};
