import { AddServicePage } from "App/Pages/AddServicePage";
import { LoginPage } from "App/Pages/LoginPage";
import { OidcLogoutRedirectPage } from "App/Pages/OidcLogoutRedirectPage";
import { OidcRedirectPage } from "App/Pages/OidcRedirectPage";
import { ServicePreviewPage } from "App/Pages/ServicePreviewPage";
import { UpdateServicePage } from "App/Pages/UpdateServicePage";
import { getAccessToken } from "App/Services";
import React from "react";
import { useSelector } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import { HomePage } from "../Pages/HomePage";
import { NotFound } from "../Pages/NotFound";
import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";

export const Router = () => {
  const accessToken = useSelector(
    ({
      user: {
        user: { access_token: accessToken },
      },
    }) => accessToken
  );
  // useEffect(() => {
  //   const refreshAccessToken = async () => {
  //     return await getAccessToken();
  //   };

  //   refreshAccessToken();
  // }, [accessToken]);
  return (
    <Switch>
      <PublicRoute
        authenticated={accessToken}
        path="/login"
        component={LoginPage}
      />
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/services"
        component={HomePage}
      />
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/services/addService"
        component={AddServicePage}
      />
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/services/service/:serviceId"
        component={ServicePreviewPage}
      />
      <PrivateRoute
        exact={true}
        authenticated={accessToken}
        path="/services/service/:serviceId/update"
        component={UpdateServicePage}
      />

      <Route path="/auth/oidc-redirect">
        <OidcRedirectPage />
      </Route>

      <Route path="/auth/oidc-logout-redirect">
        <OidcLogoutRedirectPage />
      </Route>

      {!accessToken ? (
        <Redirect from="/" exact={true} to="/services" />
      ) : (
        <Redirect from="/" exact={true} to="/login" />
      )}
      <Route path="/404">
        <NotFound />
      </Route>
      <Route path="*" render={() => <Redirect to="/404" />} />
    </Switch>
  );
};
