import tw from "tailwind-styled-components";

export const Container = tw.div`
    m-12
    max-w-full
    bg-white
    p-2
    rounded
`;

export const Content = tw.p`
    transform
    hover:scale-110 duration-700 ease-in-out
    cursor-pointer
    mt-2
    text-lg
    underline
    hover:text-blue-500
`;
export const StyledButton = tw.div`
    bg-red-600
    float-right
    py-1
    px-2
    rounded-lg
    transition
    duration-500
    ease-in-out
    hover:bg-red-600
    transform
    hover:-translate-y-0
    hover:scale-105
    cursor-pointer
    text-white
`;

export const PageHeader = tw.div`
    flex
    flex-row
    justify-between
    items-center
    text-xl
    font-medium
    py-0.5
    px-4
    bg-red-600
    rounded-t-md
    text-white
`;

export const SubmitButton = tw.button`
${({ disabled }) =>
	disabled
		? `bg-gray-400 `
		: `hover:bg-red-700  bg-red-600 focus:ring-offset-red-200 focus:ring-red-500`}
    py-2
    px-2
    flex
    justify-center
    items-center
    w-full
    text-white
    transition
    ease-in
    duration-200
    text-center
    text-base
    font-semibold
    shadow-md
    focus:outline-none
    focus:ring-2
    focus:ring-offset-2
    rounded-lg
`;

export const Input = tw.input`
    ${({ errormessage }) => errormessage && `ring-red-500 `}
    ${({ disabled }) =>
		disabled ? `bg-gray-100 text-gray-400` : `text-gray-700 `}
    rounded-md
    flex-1
    border-solid
    border-1
    border-gray-300
    w-full
    mt-1
    py-1.5
    px-4
    bg-white
    placeholder-gray-400
    shadow-sm
    text-base
    focus:ring-blue-600
`;

export const SearchInput = tw.input`
    rounded-md
    flex-1
    border
    border-gray-300
    w-full
    mt-1
    h-8
    mb-1.5
    px-4
    bg-white
    text-gray-700
    placeholder-gray-400
    shadow-sm
    text-base
    focus:outline-none
    focus:ring-2
    focus:ring-red-600
    focus:border-transparent

`;

export const EditStyledButton = tw.div`
    flex
    gap-2
    border-2
    border-white
    my-1
    transition
    duration-500
    ease-in-out
    transform
    hover:-translate-y-0
    hover:scale-110
    hover:bg-red-700
    cursor-pointer
    rounded-md
    px-1
    text-sm
`;
