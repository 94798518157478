import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { InputField } from "App/Components/Common/InputField";
import { useMutation } from "react-query";
import { ReactSelectField } from "App/Components/Common/ReactSelect";
import { useHistory } from "react-router";
import { Container, PageHeader, SubmitButton } from "App/Styled";
import { useDebouncedCallback } from "use-debounce/lib";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { Spinner } from "App/Components/Common/Spinner";
import { urlRegex } from "App/Config";

export const AddService = () => {
  const [name, setName] = useState(``);
  const [description, setDescription] = useState(``);
  // const [env, setEnv] = useState(``)
  const [errors, setErrors] = useState({
    formSubmit: false,
    nameError: ``,
    descriptionError: ``,
    // envError: ``,
  });
  const [redirectUris, setRedirectUris] = useState([
    {
      uri: `https://`,
      uriError: ``,
      purpose: ``,
      purposeError: ``,
    },
  ]);

  const [logoutRedirectUris, setLogoutRedirectUris] = useState([
    {
      uri: `https://`,
      uriError: ``,
      purpose: ``,
      purposeError: ``,
    },
  ]);

  const history = useHistory();

  //handle change input
  const handleRedirectUriInput = useDebouncedCallback((name, value, ind) => {
    const newRedirectUri = redirectUris?.map((i, index) => {
      if (index === ind) {
        i[name] = value;
      }
      return i;
    });
    setRedirectUris(newRedirectUri);
  }, 5);

  //remove location
  const removeRedirectUri = (ind) => {
    const values = [...redirectUris];
    if (values.length !== 1) {
      values.splice(ind, 1);
      setRedirectUris(values);
    }
  };

  //add location
  const addRedirectUri = () => {
    setRedirectUris([
      ...redirectUris,
      {
        uri: `https://`,
        uriError: ``,
        purpose: ``,
        purposeError: ``,
      },
    ]);
  };
  

  const handleRedirectUriErrors = (ind, name, val) => {
    const newRedirectUris = redirectUris?.map((redirectUri, index) => {
      if (index == ind) {
        redirectUri[name] = val;
      }
      return redirectUri;
    });
    setRedirectUris(newRedirectUris);
  };

    //handle change input
    const handleLogoutRedirectUriInput = useDebouncedCallback((name, value, ind) => {
      const newLogoutRedirectUris = logoutRedirectUris?.map((i, index) => {
        if (index === ind) {
          i[name] = value;
        }
        return i;
      });
      setLogoutRedirectUris(newLogoutRedirectUris);
    }, 5);

  //remove location
  const removeLogoutRedirectUri = (ind) => {
    const values = [...logoutRedirectUris];
    if (values.length !== 1) {
      values.splice(ind, 1);
      setLogoutRedirectUris(values);
    }
  };

  //add location
  const addlogoutRedirectUri = () => {
    setLogoutRedirectUris([
      ...logoutRedirectUris,
      {
        uri: `https://`,
        uriError: ``,
        purpose: ``,
        purposeError: ``,
      },
    ]);
  };

  const handleLogoutRedirectUriErrors = (ind, name, val) => {
    const newLogoutRedirectUris = logoutRedirectUris?.map((redirectUri, index) => {
      if (index == ind) {
        redirectUri[name] = val;
      }
      return redirectUri;
    });
    setLogoutRedirectUris(newLogoutRedirectUris);
  };


  const serviceAddMutation = useMutation(
    async (payload) =>
      await fetch(`${process.env.REACT_APP_BACKEND_API}/services`, {
        method: `POST`,
        headers: {
          Accept: `application/json, text/plain, */*`,
          "Content-Type": `application/json`,
        },
        body: JSON.stringify({
          ...payload,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.hasError) {
            NotificationManager.error(`Something went wrong`);
          }
          res.data && history.push(`/services`);
        }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const handleErrors = (name, value) => {
    setErrors((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  //validate form on click
  const ValidForm = () => {
    if (!name.length) {
      handleErrors(`nameError`, `Enter Name`);
    } else {
      handleErrors(`nameError`, ``);
    }

    if (!description.length) {
      handleErrors(`descriptionError`, `Enter Description`);
    } else {
      handleErrors(`descriptionError`, ``);
    }

    // if (!env?.value?.length) {
    //   handleErrors(`envError`, `Upload Image`)
    // } else {
    //   handleErrors(`envError`, ``)
    // }

    for (const index in redirectUris) {
      const redirectUri = redirectUris[index];

      if (!redirectUri.uri.length) {
        handleRedirectUriErrors(index, `uriError`, `Enter Uri`);
      } else if (!urlRegex.test(redirectUri.uri)) {
        handleRedirectUriErrors(index, `uriError`, `Enter valid uri`);
      } else {
        handleRedirectUriErrors(index, `uriError`, ``);
      }
      if (!redirectUri.purpose?.length) {
        handleRedirectUriErrors(index, `purposeError`, `Enter Purpose`);
      } else {
        handleRedirectUriErrors(index, `purposeError`, ``);
      }
    }

    for (const index in logoutRedirectUris) {
      const logoutRedirectUri = logoutRedirectUris[index];

      if (!logoutRedirectUri.uri.length) {
        handleLogoutRedirectUriErrors(index, `uriError`, `Enter Uri`);
      } else if (!urlRegex.test(logoutRedirectUri.uri)) {
        handleLogoutRedirectUriErrors(index, `uriError`, `Enter valid uri`);
      } else {
        handleLogoutRedirectUriErrors(index, `uriError`, ``);
      }
      if (!logoutRedirectUri.purpose?.length) {
        handleLogoutRedirectUriErrors(index, `purposeError`, `Enter Purpose`);
      } else {
        handleLogoutRedirectUriErrors(index, `purposeError`, ``);
      }
    }

    handleErrors(`formSubmit`, true);
  };

  useEffect(() => {
    let formValidated = false;

    if (
      errors.formSubmit &&
      !errors.nameError.length &&
      !errors.descriptionError.length
    ) {
      redirectUris?.map((redirectUri) => {
        if (!redirectUri.uriError.length && !redirectUri.purposeError.length) {
          formValidated = true;
        } else {
          formValidated = false;
        }
      });

      logoutRedirectUris?.map((logoutRedirectUri) => {
        if (!logoutRedirectUri.uriError.length && !logoutRedirectUri.purposeError.length) {
          formValidated = true;
        } else {
          formValidated = false;
        }
      });
    }
    if (formValidated) {
      //call API
      const redirectUrisPayload = redirectUris?.map((redirectUri) => {
        let data = {
          uri: redirectUri.uri,
          purpose: redirectUri.purpose,
        };
        return data;
      });

      const logoutRedirectUrisPayload = logoutRedirectUris?.map((logoutRedirectUris) => {
        let data = {
          uri: logoutRedirectUris.uri,
          purpose: logoutRedirectUris.purpose,
        };
        return data;
      });
      if (redirectUrisPayload?.length && logoutRedirectUrisPayload.length) {
        serviceAddMutation.mutate({
          name: name,
          description: description,
          redirectUris: redirectUrisPayload,
          postLogoutRedirectUris:logoutRedirectUrisPayload
        });
        handleErrors(`formSubmit`, false);
      }
    }
  }, [errors]);

  // const loadEnviornment = () => {
  //   const options = {
  //     options: [{value:"PRODUCTION",label:"Production"},{value:"STAGING",label:"Staging"}],
  //     hasMore: false,
  //   }
  //   return options
  // }

  return (
    <Container>
      <NotificationContainer />
      <PageHeader>
        <div>Add Service</div>
      </PageHeader>
      <Spinner mLoading={serviceAddMutation.isLoading} />

      {/* service basic forms */}
      <div
        className={`px-8 py-1 ${
          serviceAddMutation.isLoading && `opacity-30 pointer-events-none`
        } `}
      >
        <div className="flex gap-6 pt-2">
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Name"
              placeholder="Enter Name"
              errorMessage={errors.nameError}
              value={name}
              setValue={(e) => setName(e.target.value)}
            />
          </div>
          {/* <div className="flex-1 relative">
            <ReactSelectField
              title="Environment"
              placeholder="Select Regions"
              errorMessage={errors.envError}
              value={env}
              setValue={(e) => setEnv(e)}
              loadOptions={loadEnviornment}
              isSearchable={true}
            />
          </div> */}
          <div className="flex-1 relative">
            <InputField
              type="text"
              title="Description"
              placeholder="Enter Description"
              errorMessage={errors.descriptionError}
              value={description}
              setValue={(e) => setDescription(e.target.value)}
            />
          </div>
        </div>

        {/* Locations starts from here */}
        <PageHeader className="mt-4">
          <div>Redirect Uris</div>
        </PageHeader>
        {redirectUris.length > 0 &&
          redirectUris?.map((redirectUri, index) => (
            <div key={index} className="grid grid-cols-12">
              <div className="col-span-11 flex gap-6 ">
                <div className="flex-1 relative">
                  <InputField
                    type="text"
                    title="Uri"
                    placeholder="Enter Uri"
                    value={redirectUri.uri}
                    errorMessage={redirectUri.uriError}
                    setValue={(e) =>
                      handleRedirectUriInput(`uri`, e.target.value, index)
                    }
                  />
                </div>
                <div className="flex-1 relative">
                  <InputField
                    type="text"
                    title="Purpose"
                    placeholder="Enter Purpose"
                    value={redirectUri.purpose}
                    errorMessage={redirectUri.purposeError}
                    setValue={(e) =>
                      handleRedirectUriInput(`purpose`, e.target.value, index)
                    }
                  />
                </div>
              </div>
              <div className="col-span-1 mt-7">
                <div className="flex items-center justify-center float-right">
                  <span
                    onClick={addRedirectUri}
                    data-tip="Add Form"
                    className="tooltip px-2 py-1.5 hover:text-red-600 cursor-pointer "
                  >
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="transform transition  hover:-translate-y-0 hover:scale-105"
                    />
                  </span>
                  <span
                    onClick={() => removeRedirectUri(index)}
                    data-tip="Remove Form"
                    className="tooltip px-2 py-1.5 hover:text-red-600 cursor-pointer "
                  >
                    <FontAwesomeIcon
                      icon={faMinus}
                      className="transform transition  hover:-translate-y-0 hover:scale-105"
                    />
                  </span>
                </div>
              </div>
            </div>
          ))}
        {/* logout redirect uris starts from here */}
        <PageHeader className="mt-4">
          <div>Logout Redirect Uris</div>
        </PageHeader>
        {logoutRedirectUris.length > 0 &&
          logoutRedirectUris?.map((redirectUri, index) => (
            <div key={index} className="grid grid-cols-12">
              <div className="col-span-11 flex gap-6 ">
                <div className="flex-1 relative">
                  <InputField
                    type="text"
                    title="Uri"
                    placeholder="Enter Uri"
                    value={redirectUri.uri}
                    errorMessage={redirectUri.uriError}
                    setValue={(e) =>
                      handleLogoutRedirectUriInput(`uri`, e.target.value, index)
                    }
                  />
                </div>
                <div className="flex-1 relative">
                  <InputField
                    type="text"
                    title="Purpose"
                    placeholder="Enter Purpose"
                    value={redirectUri.purpose}
                    errorMessage={redirectUri.purposeError}
                    setValue={(e) =>
                      handleLogoutRedirectUriInput(`purpose`, e.target.value, index)
                    }
                  />
                </div>
              </div>
              <div className="col-span-1 mt-7">
                <div className="flex items-center justify-center float-right">
                  <span
                    onClick={addlogoutRedirectUri}
                    data-tip="Add Form"
                    className="tooltip px-2 py-1.5 hover:text-red-600 cursor-pointer "
                  >
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="transform transition  hover:-translate-y-0 hover:scale-105"
                    />
                  </span>
                  <span
                    onClick={() => removeLogoutRedirectUri(index)}
                    data-tip="Remove Form"
                    className="tooltip px-2 py-1.5 hover:text-red-600 cursor-pointer "
                  >
                    <FontAwesomeIcon
                      icon={faMinus}
                      className="transform transition  hover:-translate-y-0 hover:scale-105"
                    />
                  </span>
                </div>
              </div>
            </div>
          ))}

        <div className="text-center py-3 xl:px-96 lg:px-80 md:px-72 my-4 sm:px-40">
          <SubmitButton onClick={ValidForm}>Create Service</SubmitButton>
        </div>
      </div>
    </Container>
  );
};
